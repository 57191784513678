//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import createUrl from '~/utils/mixins/createUrl'
import CoAvatar from '~/components/common/CoAvatar'
import CoProfileLink from '~/components/common/CoProfileLink'
import { formatUserRating } from '~/utils/helpers'

export default {
    name: 'CoUserBox',
    components: { CoProfileLink, CoAvatar },
    mixins: [createUrl],
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    data: () => ({
        userInfo: {}
    }),
    computed: {
        ...mapGetters({
            isMobile: 'isMobile'
        }),
        salesPercent () {
            return this.userInfo.salesPercent?.value ? `${this.userInfo.salesPercent?.value}%` : 'n/a'
        },
        verifiedTooltip () {
            return this.userInfo?.achievements?.verified &&
                `<div class="c-verified-tooltip">
                    The identity of this person has been verified through ID or passport verification by the industry-leading provider, Veriff.
                     Please note that although the ID of this person has been verified, Udimi members are permitted to use pen names.
                </div>`
        },
        userPromoted () {
            return this.userInfo?.achievements?.promoted?.exists && this.userInfo.achievements.promoted
        }
    },
    created () {
        this.userInfo = this.data
    },
    methods: {
        formatUserRating
    }
}
