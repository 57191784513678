//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import ElInfoBox from '~/components/elements/ElInfoBox'
import ProHeader from '~/components/profile/ProHeader'
import ProHeaderMobile from '~/components/profile/ProHeaderMobile'
import CoUserBox from '~/components/common/CoUserBox'
import { keysToCamel } from '~/utils/helpers'
import ElButtonWait from '~/components/elements/ElButtonWait'
import { TSOURCE_JV } from '@/constants/tsource'

export default {
    name: 'ProProfileRecommends',
    components: { ElButtonWait, CoUserBox, ElInfoBox, ProHeader, ProHeaderMobile },
    asyncData ({ app, params, store, req, route }) {
        return app.$api.get(`guests/profile/favourites/${params.uid_profile}`, { aff: route.query.aff })
            .then(async (res) => {
                const pageData = keysToCamel(res.meta.page_data) || {}
                const topData = pageData.top || {}

                // capture jv click for guests only
                if (!pageData?.affiliate?.user) {
                    await store.dispatch('cookies/setTsourceCookie', { uid: TSOURCE_JV })
                }

                // set cookies and register affiliate click
                pageData.affiliate && await store.dispatch('cookies/registerAffiliateClick', { ...pageData.affiliate, req, route })

                return {
                    recommends: keysToCamel(res.data) || [],
                    metaInfo: keysToCamel(res.meta) || {},
                    userInfo: topData.userInfo,
                    isAvailable: topData.isAvailable,
                    unavailableReason: topData.unavailableReason,
                    uidProfile: params.uid_profile
                }
            })
            .catch(err => app.$handleErrors(err))
    },
    data: () => ({
        uidProfile: null,
        isAvailable: true,
        unavailableReason: '',
        userInfo: {
            user: {}
        },
        metaInfo: {},
        recommends: [],
        loading: false,
        page: 1
    }),
    head () {
        return this.userInfo?.user
            ? {
                title: `${this.userInfo.user.fullname} - best solo sellers on Udimi`,
                meta: [
                    {
                        name: 'og:title',
                        property: 'og:title',
                        hid: 'og:title',
                        content: `${this.userInfo.user.fullname} - best solo sellers on Udimi`
                    },
                    {
                        name: 'twitter:title',
                        hid: 'twitter:title',
                        content: `${this.userInfo.user.fullname} - best solo sellers on Udimi`
                    },
                    {
                        hid: 'description',
                        name: 'description',
                        content: `A list of recommended Udimi solo ad sellers by ${this.userInfo.user.fullname}`
                    },
                    {
                        name: 'og:description',
                        property: 'og:description',
                        hid: 'og:description',
                        content: `A list of recommended Udimi solo ad sellers by ${this.userInfo.user.fullname}`
                    },
                    {
                        name: 'twitter:description',
                        hid: 'twitter:description',
                        content: `A list of recommended Udimi solo ad sellers by ${this.userInfo.user.fullname}`
                    },
                    {
                        hid: 'keywords',
                        name: 'keywords',
                        content: `${this.userInfo.user.fullname}. Best solo ads sellers, recommended solo ad sellers, buy solos, buy solo ads, buy email solo ad, email solo adss`
                    }
                ],
                link: [
                    { rel: 'canonical', hid: 'canonical', href: `${this.$config.siteProtocol}://${this.$config.siteHost}/p/${this.$route.params.category}/recommends` }
                ]
            }
            : {}
    },
    computed: {
        ...mapGetters({
            isMobile: 'isMobile'
        })
    },
    methods: {
        loadMore () {
            this.loading = true
            this.$api.get(`guests/profile/favourites/${this.uidProfile}`, { page: this.page + 1 })
                .then((res) => {
                    this.page += 1
                    this.recommends = [...this.recommends, ...keysToCamel(res.data)]
                })
                .catch(err => this.$handleErrors(err))
                .finally(() => (this.loading = false))
        }
    }
}
